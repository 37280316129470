<template>
  <v-card flat class="px-2">
    <transition name="fade" mode="out-in">
      <component
        :is="tabs[tab].component"
        :type="type"
        @to-detail="toDetail"
        @to-back="toBack"
      />
    </transition>
  </v-card>
</template>
<script>
// Tab
const Summary = () => import("./Summary");
const Detail = () => import("./Detail");

export default {
  components: {
    Summary,
    Detail
  },
  props: {
    isUpdate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      tab: 0,
      tabs: [
        {
          id: "summary",
          component: "Summary"
        },
        {
          id: "detail",
          component: "Detail"
        }
      ],
      type: null
    };
  },
  methods: {
    toDetail(type) {
      this.tab = 1;
      this.type = type;
    },
    toBack() {
      this.tab = 0;
    }
  }
};
</script>
